@tailwind base;

@layer base {
  body,
  html {
    @apply bg-gray-50;
  }

  /* All text */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  div {
    @apply text-gray-600 tracking-tighter glue-wide;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-xl md:text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }

  h5 {
    @apply text-base;
  }
}
