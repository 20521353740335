@tailwind base;

@layer base {
  .content-container {
    min-height: calc(
      100vh - ((theme('spacing.5') * 2) + (theme('spacing.14') * 2))
    );
  }
}

.glow-on-hover {
  z-index: 0;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(
    45deg,
    #f6d113,
    #ff7300,
    #da1f31,
    #ff00c8,
    #7a00ff,
    #403b95,
    #1d4697,
    #2f8196,
    #f6d113
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 12px;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 12px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* @todo: move this out of this app */
